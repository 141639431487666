import './bootstrap';
import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
// import Alpine from 'alpinejs';

// window.Alpine = Alpine;

// Alpine.start();
import '../css/bootstrap.min.css';
import '../css/font-awesome.min.css';
import '../css/elegant-icons.css';
import '../css/nice-select.css';
import '../css/jquery-ui.min.css';
import '../css/owl.carousel.min.css';
import '../css/slicknav.min.css';
import '../css/style.css';
import './jquery.slicknav.js';
import './mobile-menu.js';
import './category.js';
import './humberger.js';
import './bootstrap.min.js';
import './jquery.nice-select.min.js';
import './jquery-ui.min.js';
import './mixitup.min.js';
import './owl.carousel.min.js';
// import './main.js';
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
// import 'font-awesome/css/font-awesome.min.css';
// import 'jquery-ui/dist/jquery-ui.min.js';
